/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



  var $animation_elements = $('.triangle-wrap');
  var check_if_in_view = function() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);
    var docHeight = $(document).height();

    var scrollPercent = (window_top_position) / (docHeight - window_height);
    var scrollPercentRounded = Math.round(scrollPercent*100);
    //console.log(scrollPercentRounded);

    $.each($animation_elements, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
        $element.find('svg').each(function(index){
          var pos = (element_top_position - window_top_position) * -((index+1)/6.43);
          //console.log(rotate);
          // 100% = 20deg
          //var rotate = 50 * index * scrollPercentRounded / 100;
          $(this).css({"transform":"translateY("+pos+"px)"});
        });
      }

    });
  };

  $('body').sectionScroll({topOffset: 0});
  $('body').on('section-reached', function(){
    var activeSection = $('body').sectionScroll.activeSection;
    if (activeSection.length) {
      activeSection.addClass('in-viewport');
    }
  });


  var md = new MobileDetect(window.navigator.userAgent);
  if (!md.phone()) {
    $('#animationImage').flipbook({
      'start': 0,
      'end': 400,
      'loop': false,
      'fps': 15,
      'step': 2,
      'images': imgPath+lang+'/animation/Animation_00%3d.png',
      'restartAfter': 3000,
      'onStart': function(){
        $('#animation-loader').hide();
      }
    });
  } else {
    $('#animation-loader').hide();
    $('#animationImage').attr('src', imgPath+lang+'/animation/Animation_00500.png');
  }

  $('.vita-opener').on('click', function (e) {
    e.preventDefault();
    var parent = $(this).parent().parent();
    parent.addClass('anim');
  });

  $('.vita-close').on('click', function (e) {
    e.preventDefault();
    var parent = $(this).parent().parent();
    parent.removeClass('anim');
  });

  var $totop = $('.to-top');
  $totop.on('click', function (e) {
    e.preventDefault();
    $('html, body').stop().animate({
      scrollTop: 0
    }, 500);
  });

  $window = $(window);
  $window.scroll();
  $window.on('scroll', function () {
    var fromTop = $window.scrollTop();
    if (fromTop > 100 && !$totop.hasClass('show')) {
      $totop.addClass('show');
    } else if (fromTop < 100) {
      $totop.removeClass('show');
    }

    check_if_in_view();
  });







//OPEN TRIGGER
  var openTrigger = $('.menu-trigger');
  var openTriggerTop = openTrigger.find('.menu-trigger-bar.top');
  var openTriggerMiddle = openTrigger.find('.menu-trigger-bar.middle');
  var openTriggerBottom = openTrigger.find('.menu-trigger-bar.bottom');

//CLOSE TRIGGER
  var closeTrigger = $('.close-trigger');
  var closeTriggerLeft = closeTrigger.find('.close-trigger-bar.left');
  var closeTriggerRight = closeTrigger.find('.close-trigger-bar.right');


//MENU
  var menuContainer = $('.menu-container');
  var mobileMenu = $('.mobile-menu');
  var menuTop = $('.menu-bg.top');
  var menuMiddle = $('.menu-bg.middle');
  var menuBottom = $('.menu-bg.bottom');

//TL
  var tlOpen = new TimelineMax({paused: true});
  var tlClose = new TimelineMax({paused: true});

//OPEN TIMELINE
  tlOpen.add("preOpen")
    .to(openTriggerTop, 0.4, {
      x: "+80px", y: "-80px", delay: 0.1, ease: Power4.easeIn, onComplete: function() {
        //closeTrigger.css('z-index','25');
      }
    }, "preOpen")
    .to(openTriggerMiddle, 0.4, {
      x: "+=80px", y: "-=80px", ease: Power4.easeIn,
      onComplete: function() {
        openTrigger.css('visibility','hidden');
        //console.log(content);
        //content.hide();
      }
    }, "preOpen")
    .to(openTriggerBottom, 0.4, {
      x: "+=80px", y: "-=80px", delay: 0.2, ease: Power4.easeIn
    }, "preOpen")
    .add("open", "-=0.4")
    .to(menuTop, 0.8, {
      y: "13%",
      ease: Power4.easeInOut
    }, "open")
    .to(menuMiddle, 0.8, {
      scaleY: 1,
      ease: Power4.easeInOut
    }, "open")
    .to(menuBottom, 0.8, {
      y: "-114%",
      ease: Power4.easeInOut
    }, "open")
    .fromTo(menuContainer, 0.6, { opacity: 0, visibility: 'hidden'
    }, {opacity: 1, visibility: 'visible', ease: Power4.easeOut
    }, "-=0.2")
    .add("preClose", "-=0.8")
    .to(closeTriggerLeft, 0.8, {
      x: "-=100px", y: "+=100px", ease: Power4.easeOut
    }, "preClose")
    .to(closeTriggerRight, 0.8, {
      x: "+=100px", y: "+=100px", delay: 0.2, ease: Power4.easeOut
    }, "preClose");


  //CLOSE TIMELINE
  tlClose.add("close")
    .to(menuContainer, 0.6, {opacity: 0, ease: Power4.easeOut, onComplete: function() {
        openTrigger.css('visibility','visible');
        menuContainer.css('visibility','hidden');
      }
    }, "close")
    .to(menuTop, 0.8, {
      y: "-113%",
      ease: Power4.easeInOut
    }, "close", "+=0.2")
    .to(menuMiddle, 0.8, {
      scaleY: 0,
      ease: Power4.easeInOut
    }, "close", "+=0.2")
    .to(menuBottom, 0.8, {
      y: "23%",
      ease: Power4.easeInOut,
      onComplete: function() {
       // mobileMenu.hide();
        mobileMenu.css('left','-100%');
      }
    }, "close", "+=0.2")
    .to(closeTriggerLeft, 0.2, {
      x: "+=100px", y: "-=100px", ease: Power4.easeIn
    }, "close")
    .to(closeTriggerRight, 0.2, {
      x: "-=100px", y: "-=100px", delay: 0.1, ease: Power4.easeIn
    }, "close")
    .to(openTriggerTop, 1, {
      x: "-=80px", y: "+=80px", delay: 0.2, ease: Power4.easeOut
    }, "close")
    .to(openTriggerMiddle, 1, {
      x: "-=80px", y: "+=80px", ease: Power4.easeOut
    }, "close")
    .to(openTriggerBottom, 1, {
      x: "-=80px", y: "+=80px", delay: 0.1, ease: Power4.easeOut
    }, "close");

  //EVENTS
  openTrigger.on('click', function(){
    //mobileMenu.show();
    mobileMenu.css('left','0');
    if(tlOpen.progress() < 1){
      tlOpen.play();
    } else {
      tlOpen.restart();
    }
  });

  closeTrigger.on('click', function(){
    if(tlClose.progress() < 1){
      tlClose.play();
    } else {
      tlClose.restart();
    }
  });


  $('.menu-item').on('click', function (e) {
    var href = $(this).find('a').attr('href'),
      offsetTop = href === '#' ? 0 : $(href).offset().top;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 500, function() {
      closeTrigger.trigger('click');
    });

    e.preventDefault();
  });

})(jQuery); // Fully reference jQuery after this point.




/*
var map;
function initMap() {
  var myLatLng = {lat: 48.134750, lng: 11.568390};

  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 16,
    center: myLatLng
  });

  var image = {
    url: imgPath + 'hexagon_small.png'
  };

  var marker = new google.maps.Marker({
    position: myLatLng,
    icon: image,
    map: map,
    title: 'springer | kuss'
  });

  marker.setMap(map);


  google.maps.event.addDomListener(window, "resize", function() {
    var center = map.getCenter();
    google.maps.event.trigger(map, "resize");
    map.setCenter(center);
  });

  var txt_atterneys = lang === 'en' ? 'Attorneys' : 'Rechtsanwälte';
  var infowindow = new google.maps.InfoWindow({
    content: "<span><b>springer | kuss<br> "+txt_atterneys+"</b><br> Sendlinger Straße 50<br>D-80331 München<br><br>T +49 89 [XXXXXXXX]<br>office@springer-kuss.com</span>"
  });
  infowindow.open(map,marker);

  map.setCenter(map.getCenter());

  marker.addListener('click', function() {
    infowindow.open(map,marker);
  });

}

*/